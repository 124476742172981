import React from 'react'
import styled from '@emotion/styled/macro'
import Icon from './IconBase'

const SVG = styled(Icon)``

const Swap = ({ active, className }) => (
  <SVG
    width="26"
    height="26"
    active={active}
    className={className}
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m35.292 44.996h-11.302c-1.661 0-3.008-1.347-3.008-3.008v-10.639l4.334.835c1.5 0 2.256-1.809 1.197-2.874l-3.019-3.019-5.821-5.821c-.335-.329-.763-.5-1.197-.5-.428 0-.855.171-1.19.5l-5.821 5.821-3.018 3.019c-1.059 1.065-.303 2.874 1.197 2.874l4.334-.835v14.224c0 4.654 3.773 8.428 8.428 8.428h.576 14.498c2.532 0 4.576-2.091 4.5-4.64-.074-2.465-2.223-4.365-4.688-4.365z" />
    <path d="m28.708 19.004h11.302c1.661 0 3.008 1.347 3.008 3.008v10.639l-4.334-.835c-1.5 0-2.256 1.809-1.197 2.874l3.019 3.019 5.821 5.821c.335.329.763.5 1.197.5.428 0 .855-.171 1.19-.5l5.821-5.821 3.019-3.019c1.059-1.065.303-2.874-1.197-2.874l-4.334.835v-14.223c0-4.654-3.773-8.428-8.428-8.428h-.576-14.499c-2.532 0-4.576 2.091-4.5 4.64.074 2.464 2.223 4.364 4.688 4.364z" />
  </SVG>
)

export default Swap
