import React from 'react'
import styled from '@emotion/styled/macro'
import Icon from './IconBase'

const SVG = styled(Icon)``

const Trade = ({ active, className }) => (
  <SVG
    width="26"
    height="26"
    active={active}
    className={className}
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m256.137 145.851c7.631 3.245 15.02 6.925 22.152 11.021 5.52.412 13.198.069 13.196-7.31 0 .001-.316-142.276-.316-142.276-.01-4.663-4.312-8.119-8.84-7.11-67.068 14.945-120.621 65.985-139.474 131.806 38.08-6.051 77.909-1.283 113.282 13.869z" />
    <path d="m174.986 160.509c-208.281-1.404-239.076 308.155-38.337 350.944 3.47.781 6.782-1.852 6.773-5.437l-.304-136.553c-.007-3.068-2.483-5.552-5.535-5.552h-18.776c-6.04 0-9.688-6.718-6.421-11.825 83.106-121.949 42.76-121.913 125.882-.001 3.268 5.107-.38 11.825-6.42 11.825h-21.208c-3.062 0-5.542 2.499-5.535 5.577l.304 136.948c.008 3.512 3.217 6.17 6.635 5.444 202.884-41.045 172.551-353.162-37.058-351.37z" />
    <path d="m510.635 181.329c-1.331-88.048-62.697-161.457-144.767-180.7-4.551-1.067-8.903 2.431-8.892 7.129l.315 141.837c.009 4.021 3.254 7.277 7.254 7.277h20.114c5.74 0 9.207 6.384 6.102 11.237l-38.698 60.502c24.643 39.874 35.065 88.135 29.326 134.784 75.707-25.087 130.523-97.544 129.246-182.066z" />
  </SVG>
)

export default Trade
